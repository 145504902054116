import React from 'react';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';


export default function Faq() {
  return (
    <>

<div className="section-head col-sm-12 mt-5">
    <h4><span>Frequently Asked</span> Questions</h4>
</div>

<div className="container">
<MDBAccordion alwaysOpen initialActive={1}>
      <MDBAccordionItem collapseId={1} headerTitle='What are the advantages of studying internationally?'>
      Studying abroad presents a myriad of advantages, including valuable industry exposure through internships and work opportunities, in-depth knowledge acquisition, the opportunity to earn an international degree, and the enhancement of career prospects with a globally recognized qualification. 
      A key advantage lies in the hands-on exposure that enables students to comprehend diverse work cultures, foster practical skills, and cultivate a robust professional network. Furthermore, obtaining an international degree not only enhances academic credentials but also showcases a global perspective, greatly expanding career opportunities in our increasingly interconnected world.
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle='What are some most popular countries for studying abroad?'>
      There are various countries that are widely regarded as excellent study abroad destinations. Some popular study abroad destinations include the United States, United Kingdom, Canada, Australia, and France. These countries have prestigious universities and educational institutions, providing a wide variety of academic programs, and an immersive cultural experience for international students. However, the Ideal study-abroad destination is determined by personal preferences, academic ambitions, and the specific field of study. 
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle='Which exams are necessary to pursue education abroad?'>
      The exams required to study abroad can vary depending on the country, university, and specific program you are applying to. Some most common exams include the IELTS (International English Language Testing System) for English proficiency, TOEFL (Test of English as a Foreign Language). For graduate-level programs specifically in the USA, the GRE (Graduate Record Examination) or GMAT (Graduate Management Admission Test) are often required.
      To ascertain the precise examinations needed for your desired study abroad destination, it is crucial to conduct thorough research and seek guidance from an experienced study abroad counselor.
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={4} headerTitle='When can I begin the application process for study abroad programs?'>
      Depending on the programme and university, the study abroad application process often begins many months in advance. It is advised that you conduct research and prepare for study abroad at least 6-12 months before your  start date. This will give you plenty of time to acquire all of the relevant documentation, such as transcripts and recommendation letters, take any required exams, such as language proficiency tests, and meet application deadlines. 
      Starting early ensures that you have a sufficient amount of time to concentrate on the application process and boosts your chances of getting into your desired study abroad program.
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={5} headerTitle="How do Globedwise's Pre-Departure Sessions Help students in the study abroad packing process?">
      As it involves preparing for an extended stay in a foreign place, packing for study abroad might be difficult. However, with the right direction and resources, the process can be greatly simplified. 

For instance, Globedwise hosts Pre-Departure sessions that help students get ready for their study abroad journey.These sessions provide valuable knowledge and guidance on various aspects of packing, including what essentials to bring, how to pack efficiently, and tips for managing luggage restrictions. In this session, students can obtain practical insights and receive assistance in making well-informed packing choices. This enables them to pack confidently and ensure they have everything they need for a successful study abroad expedition.
      </MDBAccordionItem>
    </MDBAccordion>

</div>


    
    </>
  )
}
