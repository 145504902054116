import axios from 'axios'
import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';

export default function Quicksteps() {

  const navigate = useNavigate();

    const formurl="https://backend.globedwise.com/zoho"
    const [formData , setformData] = useState({
      firstname:'',
      lastname:'',
      emailaddress:'',
      phone:''
    });

    type formData = {
      firstname: string;
      lastname: string;
      emailaddress: string;
      phone: string;
    };
  
    const handleglobeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const newData = { ...formData, [name]: value };
      setformData(newData);
      setShowMessage(false);
    };

    const [showMessage, setShowMessage] = useState(false);
  
    const handleSubmit=(e: { preventDefault: () => void; })=>{
      e.preventDefault();
      if (formData.firstname === '' || formData.lastname === '' || formData.emailaddress === '' || formData.phone === '') {
        setShowMessage(true);
        return;
      }

      axios.post(formurl,{
      data : [
        {
            Last_Name: formData.lastname,
            First_Name: formData.firstname,
            Email: formData.emailaddress,
            Mobile: formData.phone,
            Lead_Source: "IELTS Landing Page",
            Lead_Stage: "New"
        }
    ],
    trigger : [ "approval", "workflow", "blueprint"],
    lar_id : "3654871000006222333"
    }
      
      )
      .then((response)=>{
        setformData({
            firstname:'',
            lastname:'',
            emailaddress:'',
            phone:''
        });
        // alert('Your Form is Submitted')
        console.log(response);
        alert('Your form is submitted')
        navigate('/thankyou');
      })
      .catch((error) => {
        console.log(error);
        // Handle error and display appropriate message to the user
      });

    }
  return (
    <>

    {/* offline couselling modal start */}

<div className="modal fade pt-4 mt-5" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex:'9999'}}>
  <div className="modal-dialog modal-dialog-scrollable ">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Please Fill Your Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="landingform">
      {showMessage && (
            <div className="alert alert-danger" role="alert">
              Please fill in all fields.
            </div>
      )}
        <form>
                <div className="form-outline mb-1">
                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>First Name</label>
                  <input required type="text" name="firstname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.firstname} placeholder='Enter Your First name'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Last Name</label>
                  <input required type="text" name="lastname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.lastname} placeholder='Enter Your Last name'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Email address</label>
                  <input required type="email" name="emailaddress" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.emailaddress} placeholder='Enter Your Email Address'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Mobile No.</label>
                  <input required type="number" name="phone" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.phone} placeholder='Enter Your Mobile No.'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                
                


                {/* <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Select Date</label>
                  <input required type="datetime-local" name="date" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.date}/> */}
                </div>

        
                <button type="submit" onClick={handleSubmit} className="btn btn-danger btn-block mt-4 shadow-sm rounded-3 w-100">Register Here</button>

              </form>
        </div>
      </div>
     
    </div>
  </div>
</div>

{/* offline couselling modal ended */}

    <div className="mainquick">
        <div className="row px-3 py-3 mx-0">
            <div className="col-md-4  d-flex flex-column justify-content-center">
            <h3 className='text-light text-capitalize'>Transfer you future, study abroad with expert guidance</h3>
            </div>

            <div className="col-md-4">
            <div className="quickline">
            <div className="rightChapterBlockIcon">
                        <ul>
                            <li><p><i className="fa fa-search" aria-hidden="true"></i></p>
                                <span>Schedule a Meeting</span>
                            </li>
                            <li><p><i className="fa fa-envelope" aria-hidden="true"></i></p>
                                <span>Enter Your Details</span>
                            </li>
                            <li><p><i className="fas fa-check" aria-hidden="true"></i></p>
                                <span>Get free counseling</span>
                            </li>
                        </ul>
                    </div>
    
            </div>
            </div>

            {/* <div className="col-md-3 text-center">
       <div className="d-flex flex-row justify-content-center">
  <button className="btn btn-sm btn-danger mx-1 mt-3" type="button" aria-expanded="false">
 <a className='text-light' href="https://crm.zoho.com/bookings/GlobedwiseStudyAbroadCounsellingSession?rid=ca404eb1ef7a80447dfc8a423120b3fc026adab58d270808dba169946447be87gidd698835d2c511585591874e9c4ef4f12b20a68a5b65ef8257513fb943617476e">Book Online Counselling</a> 
  </button>
  <button className="btn btn-sm btn-danger mx-1 mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" aria-expanded="false">
  Book Offline Counselling
  </button>
  </div>

            </div> */}

            <div className="col-md-4 px-3 d-flex flex-column justify-content-center mobile-quickline">
            <h4 className="text-white text-center">Globedwise Students Recruited in Various Institutions </h4>
            <p className="text-white mb-0 text-center"><a className="text-white" href="#institutions"> <u>Check Now</u></a> </p>
            </div>
        </div>
    </div>
    
    </>
  )
}
