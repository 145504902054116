import React from 'react'

export default function Thankyoupage() {
  return (
    <>
    <div className='content' style={{marginTop:'15rem'}}>
  <div className="wrapper-11">
    <div className="wrapper-2">
      <h1 className='thankyouh1'>Thank you !</h1>
      <p>Thanks for Submitting the Form  </p>
      <p>You should receive a confirmation call soon ! </p>
      <a href="/" className='text-light'>
      <button className="go-home">
      Continue to Home
      </button>
      </a>
    </div>
    <div className="footer-like">
      <p>Want Instant Reply ?
       <a href="tel:919696875875"> Call Us Now</a>
      </p>
    </div>
</div>
</div>

    </>
  )
}
