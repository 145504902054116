import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>

    <div className="container-fluid bg-light2 text-dark footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">


        <div className="container py-5">
            <div className="row g-5">

                <div className="col-lg-6 col-md-6">
                    <h4 className="text-primary mb-3">Our Locations</h4>
                    <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                    <strong> Prashant Vihar, New Delhi </strong> <br />
                    <span className="text-dark">A-54, Prashant Vihar, Opposite Lancer Convent School, Sector – 14, Rohini,</span> 
                    </p>
                    <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                    <strong> Rajouri Garden, New Delhi</strong> <br />
                    <span className="text-dark">J-4/1, Rajouri Garden, New Delhi – 110027, India</span>
                    </p>
                   
                </div>
                <div className="col-lg-6 col-md-6">
                    <h4 className="text-primary mb-3">Our Locations</h4>
                    
                    <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                    <strong> Vijay Nagar, New Delhi </strong> <br />
                   <span className="text-dark"> H-15, Vijay Nagar, Delhi University Area, Delhi – 110009, India</span></p>
                    <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                    <strong> Chandigarh,</strong> <br />
                   <span className="text-dark"> SCO 315-316, First Floor, Sector 35B, Chandigarh -160022, India</span>
                    </p>
                </div>
            
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom text-primary" href="javascript:void(0)">Copyright © 2023 GlobEDwise</a>, All Right Reserved.
                    </div>
                    {/* <div className="col-md-6 text-center text-md-end">
                    <div className="socail1">
    <a className="customicon mx-2" href="https://twitter.com/globedwise" target="_blank"><i className="fab fa-twitter"></i></a>
    <a className="customicon mx-2" href="https://www.linkedin.com/company/globedwise/" target="_blank"><i className="fab fa-linkedin"></i></a>
    <a className="customicon mx-2" href="https://www.facebook.com/Globedwise/" target="_blank"><i className="fab fa-facebook"></i></a>
    <a className="customicon mx-2" href="https://www.youtube.com/@globedwisestudyabroad/featured" target="_blank"><i className="fab fa-youtube"></i></a>
    <a className="customicon mx-2"href="https://www.instagram.com/globedwise_india/" target="_blank"><i className="fab fa-instagram"></i></a>    
    </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>


    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>


   
    
    </>
  );
}
