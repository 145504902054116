import React from 'react';
import { Helmet } from 'react-helmet'

import whoweare from "../assets/img/counselingbg.jpg"

// flags import
import UK from '../assets/flags/UK.png'
import US from '../assets/flags/US.png'
import Canada from '../assets/flags/Canada.png'
import Australia from '../assets/flags/Australia.png'
import germany from '../assets/flags/Germany.jpg'
import NewTesti from './NewTesti';
import Faq from './Faq';
import ReasonsAbroad from './ReasonsAbroad';
// flags import
import globedwiseuni from '../assets/img/globedwiseuni.png'

export default function OnlineCounseling() {
  return (
    <>
    <Helmet>
        <title>Book Online Counseling</title>
        <meta name="description" content="The Ease and Experience Of Overseas Education around the world." />
        <meta name="google-site-verification" content="_AVkTsAnt6UJmKSzOeFHzxvpuxb4-Bsak9Y9_iKw5_k" />
        <link href="https://globedwise.com/onlinecounseling" rel="canonical" />
        <meta name="robots" content="index, follow"></meta>
    </Helmet>

    {/* hero section start */}
    <div id='#customcontainer' className="customcontainer mt-5 pt-2">
<div className="blog-bg-image d-flex justify-content-center align-items-center"
  style={{backgroundImage: `url(${whoweare})`,height:'450px',backgroundSize:'cover', backgroundPosition:'top', backgroundColor:'rgba(0, 0, 0, 0.0)', backgroundBlendMode:'overlay'}}>
    
    <div className="row blogherosec w-100 mx-0 mt-5">
     <div className="col-md-8 bloglandingcontent">
     <h1 className="bannerHeading text-primary">Online Counseling </h1>
		<h2 className='bannerHeading text-dark'>The Ease and Experience Of Overseas<span className='text-danger'> <br />
         Education </span>around the world.</h2>

                <div className="d-flex flex-row mt-3">
                    <img src={UK} className='mt-2 mx-2 img-fluid flag-img' style={{width:'100px'}}/>
                    <img src={US} className='mt-2 mx-2 img-fluid flag-img' style={{width:'100px'}}/>
                    <img src={Canada} className='mt-2 mx-2 img-fluid flag-img' style={{width:'100px'}}/>
                    <img src={Australia} className='mt-2 mx-2 img-fluid flag-img' style={{width:'100px'}}/>
                    <img src={germany} className='mt-2 mx-2 img-fluid flag-img rounded-3' style={{width:'100px'}}/>
                </div>
     </div> 

     
    </div>
 
</div>

 </div>

    <div className="onlinesec">
      <iframe
        width="100%"
        height="1100px"
        src="https://globedwise.zohobookings.com/portal-embed#/customer/studyabroadonlinecounseling"
        frameBorder="0"
        allowFullScreen
        title="Online Counseling">
      </iframe>
      
      </div>

      <ReasonsAbroad/>

  <div className="section-head col-sm-12 mb-1 mt-3">
      <h4><span>Globedwise Students Recruited In</span> Various Institutions</h4>
  </div>

  <div className="container text-center">
    <img src={globedwiseuni} alt="" className="img-fluid" width={950} />
  </div>

      <NewTesti/>

      <Faq/>
    </>
  );
}