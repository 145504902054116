import React from 'react'

import icon1 from '../assets/customicons/accomodation.png'
import icon2 from '../assets/customicons/diet.png'
import icon3 from '../assets/customicons/bus-stop.png'
import icon4 from '../assets/customicons/bill.png'
import icon5 from '../assets/customicons/programmer.png'
import icon6 from '../assets/customicons/assistance.png'
import icon7 from '../assets/customicons/application.png'
import icon8 from '../assets/customicons/scholarship.png'
import icon12 from '../assets/customicons/passport.png'
import icon11 from '../assets/customicons/visa.png'
import icon10 from '../assets/customicons/conversation.png'
import icon9 from '../assets/customicons/education.png'
import newlogo1 from '../assets/customicons/newlogo1.png'
import newlogo2 from '../assets/customicons/newlogo2.png'
import newlogo3 from '../assets/customicons/newlogo3.png'
import newlogo4 from '../assets/customicons/newlogo4.png'
import newlogo5 from '../assets/customicons/newlogo5.png'
import newlogo6 from '../assets/customicons/newlogo6.png'
export default function WhyChoose() {
  return (
    <>
    
    <div className="feat bg-gray pt-5 pb-5" id='RAC'> 
    <div className="section-head col-sm-12 mt-5" id='faq'>
    <h4><span>Reasons to choose GlobEDwise for</span> your Overseas Education</h4>
  </div>
    <div className="custom-container text-center mx-2">
      <div className="row mx-0 justify-content-center">
        <div className="col-xs-6 col-md-3">
          <div className="customitem px-1">
          <img src={icon10} width={50} className='mb-3 mt-2' alt="Counseling " />
            <h6 className='text-primary mb-1'>Education Counseling </h6>
            <p>Personalized counselling that helps in making the right choice.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-3">
          <div className="customitem">
            <img src={newlogo1} width={50} className='mb-3 mt-2' alt="Profiling" />
            <h6 className='text-primary mb-1'>Extensive Profiling</h6>
            <p>Advanced Tools to assess Individual Profiles.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-3">
          <div className="customitem">
          <img src={icon9} width={50} className='mb-3 mt-2' alt="Program" />
            <h6 className='text-primary mb-1'>Program Assistance</h6>
            <p>Guidance in Finalizing the apt Programs & Educational institutions.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-3">
          <div className="customitem">
          <img src={icon7} width={50} className='mb-3 mt-2' alt="Coaching" />
            <h6 className='text-primary mb-1'>Coaching</h6>
            <p>Best Trainers help in accomplishing High Scores in Standardized Tests </p>
          </div>
        </div>
        <div className="col-xs-6 col-md-3">
          <div className="customitem">
          <img src={newlogo3} width={50} className='mb-3 mt-2' alt="Scholarship" />
            <h6 className='text-primary mb-1'>Scholarship Assistance</h6>
            <p>Well-informed Team to assist scholarships for a cost-effective budget.</p>
          </div>
        </div>

        <div className="col-xs-6 col-md-3">
          <div className="customitem">
          <img src={newlogo2} width={50} className='mb-3 mt-2' alt="SOP and LOR" />
            <h6 className='text-primary mb-1 '>SOP and LOR Assistance </h6>
            <p>Thorough guidance to help in well-drafting SOP, LOR & Essays.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-3">
          <div className="customitem">
          <img src={newlogo6} width={50} className='mb-3 mt-2' alt="Admission" />
            <h6 className='text-primary mb-1'>Admission Process </h6>
            <p>Help in understanding the Technicalities of the process and assist in Making the Applications.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-3">
          <div className="customitem">
          <img src={icon8} width={50} className='mb-3 mt-2' alt="Financial" />
            <h6 className='text-primary mb-1'>Education Loan Assistance</h6>
            <p>Assist in procuring Education Loans, Payment of Tuition Fees.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-4">
          <div className="customitem">
          <img src={icon5} width={50} className='mb-3 mt-2' alt="VISA Assistance" />
            <h6 className='text-primary mb-1'>VISA Assistance</h6>
            <p>Expert Visa Team that helps in the entire Visa Filing Process.</p>
          </div>
        </div>
        <div className="col-xs-6 col-md-4">
          <div className="customitem">
          <img src={newlogo5} width={50} className='mb-3 mt-2' alt="Pre & Post Departure" />
            <h6 className='text-primary mb-1'>Pre & Post Departure</h6>
            <p>Exclusive seminars to help the students familiarize before-hand</p>
            </div>
            </div>

        </div>

    </div>
  </div>

    
    </>
  )
}
