import React from 'react'
import reasonabroad from '../assets/img/reasonabroad.png'

export default function ReasonsAbroad() {
  return (
    <>
     <div className="section-head col-sm-12 mb-1" id='why'>
          <h4><span>Reasons To Study</span> Abroad</h4>
          <p>Let your Dream becomes a Reality - Ease & Expertise of Overseas Education</p>
        </div>

  <div className="container text-center">
    <img src={reasonabroad} alt="" className="img-fluid" />
  </div>
    
    </>
  )
}
