import React from "react";
import axios from 'axios'
import { Link, NavLink, useNavigate } from "react-router-dom";
import {useState} from 'react';
import logo from '../../assets/img/globedwiselogo.png'
// modal import
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
// modal import
// flags import
import UK from '../../assets/flags/UK.png'
import US from '../../assets/flags/US.png'
import Canada from '../../assets/flags/Canada.png'
import Australia from '../../assets/flags/Australia.png'
import Germany from '../../assets/flags/Germany.jpg'
import Europe from '../../assets/flags/europe.jpg'
import NZ from '../../assets/flags/Newzeland.jpg'
import ireland from '../../assets/flags/ireland.png'
import france from '../../assets/flags/france.jpg'
import netherlands from '../../assets/flags/netherlands.png'
import denmark from '../../assets/flags/denmark.png'
import { Helmet } from "react-helmet";
// flags import


export default function Header() {
   // thankyoumodal
   const [basicModal, setBasicModal] = useState(false);

   const toggleShow = () => setBasicModal(!basicModal);
 
   // thankyoumodal
   const navigate = useNavigate();

   const formurl="https://backend.globedwise.com/eventLead"
   const [formData , setformData] = useState({
     firstname:'',
     lastname:'',
     emailaddress:'',
     phone:''
   });
 
   type formData = {
     firstname: string;
     lastname: string;
     emailaddress: string;
     phone: string;
   };
 
   const handleglobeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
     const { name, value } = e.target;
     const newData = { ...formData, [name]: value };
     setformData(newData);
     setShowMessage(false);
   };
 
   const [showMessage, setShowMessage] = useState(false);
 
   const isValidEmail = (email: string) => {
     // Basic email validation 
     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     return emailPattern.test(email);
   };
 
   const handleSubmit=(e: { preventDefault: () => void; })=>{
     e.preventDefault();
     if (
       formData.firstname === '' ||
       formData.lastname === '' ||
       !isValidEmail(formData.emailaddress) || 
       formData.phone.length !== 10 ) 
       {
       setShowMessage(true);
       return;
     }
 
     axios.post(formurl,{
     data : [
       {
           First_Name: formData.lastname,
           Name: formData.firstname,
           Email: formData.emailaddress,
           Mobile: formData.phone,
           Event_Lead_Source: "Events Landing Page"
       }
   ],
   trigger : [ "approval", "workflow", "blueprint"],
   lar_id : "3654871000006222333"
   }
     
     )
     .then((response)=>{
       setformData({
           firstname:'',
           lastname:'',
           emailaddress:'',
           phone:''
       });
       // alert('Your Form is Submitted')
       console.log(response);
       alert('Your form is submitted')
       navigate('/thankyou');
     })
     .catch((error) => {
       console.log(error);
       // Handle error and display appropriate message to the user
     });
 
   }
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

  // more destinations
  const [showAdditionalDropdown, setShowAdditionalDropdown] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);

  const toggleAdditionalDropdown = () => {
    setShowAdditionalDropdown(!showAdditionalDropdown);
    setReadMoreClicked(true); // Set to true when the link is clicked
  };
    // more destinations


  
  return (
    <>

      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1' className="mt-5">
        <MDBModalDialog className="modal-dialog-centered">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-primary">Fill In Your Details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>
                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example1" style={{marginLeft: "0px"}}>First Name</label>
                  <input type="text" name="firstname" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.firstname}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "42.4px"}}></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example1" style={{marginLeft: "0px"}}>Last Name</label>
                  <input type="text" name="lastname" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.lastname}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "42.4px"}}></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example2" style={{marginLeft: "0px"}}>Email address</label>
                  <input type="email" name="emailaddress" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.emailaddress}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "88.8px"}}></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example1" style={{marginLeft: "0px"}}>Mobile No.</label>
                  <input type="number" name="phone" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.phone}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "42.4px"}}></div><div className="form-notch-trailing"></div></div></div>

                <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-block">Submit</button>
              </form>
            </MDBModalBody>

          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    <div id="mainnavsec" className="sticky">

{/* <div className="navbarlinks pt-2">
  <div className="col-md-6"></div>
  <div className="col-md-6 uppermainheader" >
    <a href="https://events.globedwise.com/" target="_blank" className="text-light mb-1 navhovlinks">Events</a>
    <Link to="https://globedwise.com/blogs" target="_blank" className="text-light mb-1 navhovlinks">Blogs</Link>
    <a href="/" className="text-light mb-1 navhovlinks">Scholarship</a>
    <a href="https://forms.zohopublic.com/globedwise/form/Testing/formperma/pLx9SyCLJlCfE-y-j9wm4TiDNX3asRDFTECHgfzMcCA" className="text-light mb-1 navhovlinks">Check Your IELTS Level</a>
    <p className="text-light mb-1 navhovlinks border border-white rounded-3 px-2 py-1"> 
    <a className="text-white" onClick={toggleShow}>Get In Touch</a>
    </p>
    </div>
    
   </div> */}


   <div className="navhead row py-2 mx-0 px-4">
    <div className="col-md-3">
   <Link to="/"> <img src={logo} alt="" style={{width:'250px'}}/></Link>
    </div>
    <div className="col-md-9" style={{display:'flex', justifyContent: 'center' , alignItems: 'center'}}>

    <div className="socialhead col-md-12">
    {/* <div className="socail1">
    <a className="customicon mx-2" href="https://twitter.com/globedwise" target="_blank"><i className="fab fa-twitter"></i></a>
    <a className="customicon mx-2" href="https://www.linkedin.com/company/globedwise/" target="_blank"><i className="fab fa-linkedin"></i></a>
    <a className="customicon mx-2" href="https://www.facebook.com/Globedwise/" target="_blank"><i className="fab fa-facebook"></i></a>
    <a className="customicon mx-2" href="https://www.youtube.com/@globedwisestudyabroad/featured" target="_blank"><i className="fab fa-youtube"></i></a>
    <a className="customicon mx-2" href="https://www.instagram.com/globedwise_india/" target="_blank"><i className="fab fa-instagram"></i></a>    
    </div> */}
    <div className="social2">
    <div className="socialcall mx-2 btn btn-danger rounded-3 shadow-sm"> 
    <p className="mb-0"><a href="tel:919696875875" className="text-light"> <i className="fa fa-phone-alt text-light" aria-hidden="true"></i> +91 9696875875</a></p>
    </div>
    <div className="socialcall mx-2 btn btn-danger rounded-3 shadow-sm"> 
    <p className="mb-0"><a href="mailto:info@globedwise.com" className="text-light"><i className="fa fa-envelope text-light" aria-hidden="true"></i> info@globedwise.com</a></p>
    </div>
    </div>
    </div>

    {/* <div className="menuhead col-md-12 mt-1">
      
    <h5 className="mainlinks text-uppercase"><a className="text-primary" onClick={goToTop}>Why GlobEDwise</a></h5> 
  
    <h5 className="mainlinks text-uppercase"><a href="#RAC">Reason to choose</a></h5> 

    <h5 className="mainlinks text-uppercase"><a href="#why">Reasons to Study Abroad</a></h5> 

    <h5 className="mainlinks text-uppercase"><a href="#students">Institutions</a></h5> 


    </div> */}

    </div>
  </div>

</div>


    </>
  );
}
