import './App.css';
import './assets/css/bootstrap.min.css'
import { BrowserRouter  as Router ,  Routes , Route } from 'react-router-dom';
import React,{useState} from 'react';
import NewHeadercopy from './components/includes/NewHeadercopy';
import Mobileheadernew from './components/includes/Mobileheadernew';
import Footer from './components/includes/Footer';
import Thankyoupage from './components/Thankyoupage';
import EventLanding from './components/EventLanding';
import OnlineCounseling from './components/OnlineCounseling';
import Terms from './components/Terms';

function App() {

  return (

    
  <>
  <Router>
      <NewHeadercopy />
      <Mobileheadernew />
      <Routes>
        <Route path='/' element={<EventLanding/>}></Route>
        <Route path='/thankyou' element={<Thankyoupage/>}></Route>
        <Route path='/onlinecounseling' element={<OnlineCounseling/>}></Route>
        <Route path='/terms-and-conditions' element={<Terms/>}></Route>
      </Routes>
      <Footer />
    </Router>
     
    </>
   





  );
}

export default App;
