import axios from 'axios'
import React,{useState} from 'react'
// import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet'
import idpimg from '../assets/img/idp-logo.svg'
import keyfeature from '../assets/img/keyfeature.png'
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import custombg from '../assets/img/carousel-4.jpg'
import globeduni from '../assets/img/globedwiseuni.png'
import banner from '../assets/img/banner2.jpg'
import Event1banner from '../assets/img/2junebanner.jpg'
import Event1banner2 from '../assets/img/2junebannermobile.jpg'
import loan from '../assets/img/loan.png'
import { Link, useNavigate } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';


import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBAccordion,
    MDBAccordionItem,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import WhyChoose from './WhyChoose';
// import ReasonsAbroad from './ReasonsAbroad';
import { Helmet } from 'react-helmet';
import NewTesti from './NewTesti'
import ReasonsAbroad from './ReasonsAbroad'
import Faq from './Faq'
import Quicksteps from './Quicksteps'
// import YoutubeVideo from './YoutubeVideo'

export default function Ielts()  {



    // thankyoumodal
    const [basicModal, setBasicModal] = useState(false);

    const toggleShow = () => setBasicModal(!basicModal);
  
    // thankyoumodal
    const navigate = useNavigate();

    const formurl="https://backend.globedwise.com/eventLead"
    const [formData , setformData] = useState({
      firstname:'',
      lastname:'',
      emailaddress:'',
      phone:'',
      destination:'',
    });

    type formData = {
      firstname: string;
      lastname: string;
      emailaddress: string;
      phone: string;
      // destination: string;
    };
  
    const handleglobeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value, type } = e.target;
  
      if (type === 'checkbox') {
        const checkboxValue = (e.target as HTMLInputElement).checked;
        setCheckboxChecked(checkboxValue);
      } else {
        const newData = { ...formData, [name]: value };
        setformData(newData);
      }
  
      setShowMessage(false);
    };

    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
  
    const isValidEmail = (email: string) => {
      // Basic email validation 
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
  
    const handleSubmit=(e: { preventDefault: () => void; })=>{
      e.preventDefault();
      if (
        formData.firstname === '' ||
        formData.lastname === '' ||
        formData.destination === '' ||
        !isValidEmail(formData.emailaddress) || 
        formData.phone.length !== 10 
        ) 
        
        {
        setShowMessage(true);
        return;
      }

      axios.post(formurl,{
      data : [
        {
            Name: formData.lastname,
            First_Name: formData.firstname,
            Email: formData.emailaddress,
            Mobile: formData.phone,
            Campaign_Which_Country_you_want_to_study_abroad: formData.destination,
            Event_Lead_Source: "Events Landing Page",
        }
    ],
    trigger : [ "approval", "workflow", "blueprint"],
    lar_id : "3654871000006222333"
    }
      
      )
      .then((response)=>{
        setformData({
            firstname:'',
            lastname:'',
            emailaddress:'',
            phone:'',
            destination:''
        });
        // alert('Your Form is Submitted')
        console.log(response);
        // alert('Your form is submitted');
        navigate('/thankyou');
      })
      .catch((error) => {
        console.log(error);
        // Handle error and display appropriate message to the user
      });
      setCheckboxChecked(false);

    }
    const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      });
    }
     
  return (
  <>
<Helmet>
<title>Study Abroad Education Fair: Explore Study Abroad options in Canada, UK, USA, Australia, Ireland, Germany, France and other Schengen Countries, Singapore</title>
<meta name="description" content="Study Abroad Education Fair: Explore Study Abroad options in Canada, UK, USA, Australia, Ireland, Germany, France and other Schengen Countries, Singapore" />
<meta name="google-site-verification" content="_AVkTsAnt6UJmKSzOeFHzxvpuxb4-Bsak9Y9_iKw5_k" />
  </Helmet>

  {/* modal form started */}
<div className="modal fade pt-4 mt-5" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex:'9999'}}>
  <div className="modal-dialog modal-dialog-scrollable ">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Please Fill Your Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="landingform">
        <form>
                <div className="form-outline mb-1">
                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>First Name</label>
                  <input required type="text" name="firstname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.firstname} placeholder='Enter Your First name'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Last Name</label>
                  <input required type="text" name="lastname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.lastname} placeholder='Enter Your Last name'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Email address</label>
                  <input required type="email" name="emailaddress" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.emailaddress} placeholder='Enter Your Email Address'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Mobile No.</label>
                  <input required type="number" name="phone" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.phone} placeholder='Enter Your Mobile No.'/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                
                


                {/* <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Select Date</label>
                  <input required type="datetime-local" name="date" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.date}/> */}
                </div>

        
                <button type="submit" onClick={handleSubmit} className="btn btn-danger btn-block mt-4 shadow-sm rounded-3 w-100">Register Here</button>

              </form>
        </div>
      </div>
     
    </div>
  </div>
</div>
{/* modal form ended */}
  
  <div className="landingbanner mb-4" style={{marginTop:'6rem'}}>
        <div className="row col-md-12 mx-0 align-items-center justify-content-center">

        <div className="col-md-8 px-0 text-center" >
        <img src={Event1banner} className='img-fluid desktopbgchandigarh shadow' style={{height:'100%', objectFit:"contain"}} />
        <img src={Event1banner2} className='img-fluid mobilebgchandigarh ' style={{ padding:'10px 15px' ,objectFit: 'contain', objectPosition: 'right', display:'none'}} />
        </div>

        <div className="col-md-4 pb-0 px-3" >
    <div className="formhead bg-danger pt-3 pb-2 px-3">
      <h5 className='text-light text-capitalize'>Interested in studying abroad ?</h5>
    </div>
<div className="landingform bg-white shadow py-3 px-3">
{showMessage && (
    <div className="alert alert-danger" role="alert">
      Please fill in all fields.
    </div>
)}
<form>
        <div className="form-outline mb-1">
        <label className="form-label fw-bold mb-1" style={{marginLeft: "0px"}}>First Name</label>
        <input required type="text" name="firstname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.firstname} placeholder='Enter Your First name' tabIndex={0}/>
        <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

        <div className="form-outline mb-1">
        <label className="form-label fw-bold mb-1" style={{marginLeft: "0px"}}>Last Name</label>
        <input required type="text" name="lastname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.lastname} placeholder='Enter Your Last name' tabIndex={0}/>
        <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

        <div className="form-outline mb-1">
        <label className="form-label fw-bold mb-1" style={{marginLeft: "0px"}}>Email address</label>
        <input required type="email" name="emailaddress" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.emailaddress} placeholder='Enter Your Email Address' tabIndex={0}/>
        <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>



      <div className="row">
        

<div className="form-outline mb-1">
        <label className="form-label fw-bold mb-1" style={{marginLeft: "0px"}}>Mobile No.</label>
        <input required type="number" name="phone" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.phone} placeholder='Enter Your Mobile No.' tabIndex={0}/>
        <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

        </div>

        <div className="form-outline mb-1">
                <label className="form-label fw-bold mb-1" style={{marginLeft: "0px"}}>Preferred Study Abroad Destinations</label>
                <select className="form-select rounded-3 input-light2" name='destination' value={formData.destination} onChange={(e)=> handleglobeInput(e)}>
                  <option value='Please select'>--- Preferred Study Destinations ---</option>
                  <option value='Canada'>Canada</option>
                  <option value='USA'>USA</option>
                  <option value='United Kingdom'>United Kingdom</option>
                  <option value='Australia'>Australia</option>
                  <option value='France'>France</option>
                  <option value='Georgia'>Georgia</option>
                  <option value='Germany'>Germany</option>
                  <option value='Switzerland'>Switzerland</option>
                  <option value='New Zealand'>New Zealand</option>
                </select></div>

{/* 
                <div className="form-check mt-3">
               <input className="form-check-input"type="checkbox"
  checked={checkboxChecked}
  onChange={(e) => handleglobeInput(e)}
  id="flexCheckDefault"/>
                <label className="form-che  ck-label" htmlFor="flexCheckDefault">
                I have read and agreed to <Link to='/terms-and-conditions'> terms & conditions </Link>
                </label>
              </div> */}

               
        <button type="submit" onClick={handleSubmit} className="btn btn-danger btn-block mt-2 shadow-sm rounded-3 w-100">Register Here</button>

      </form>
</div>
</div>

        </div>
    </div>



{/* quick steps start */}
{/* <div className="mainquick wow fadeInUp">
<div className="row px-3 py-3 mx-0">
    <div className="col-md-2  d-flex flex-column justify-content-center">
    <h3 className='text-light'>Getting Started is Easy</h3>
    </div>

    <div className="col-md-4">
    <div className="quickline">
    <div className="rightChapterBlockIcon">
                <ul>
                    <li><p><i className="fa fa-search" aria-hidden="true"></i></p>
                        <span>Schedule a Meeting</span>
                    </li>
                    <li><p><i className="fa fa-envelope" aria-hidden="true"></i></p>
                        <span>Enter Your Details</span>
                    </li>
                    <li><p><i className="fas fa-check" aria-hidden="true"></i></p>
                        <span>Get free counseling</span>
                    </li>
                </ul>
            </div>

    </div>
    </div>

    <div className="col-md-3 text-center">
<div className="d-flex flex-row justify-content-center">
<button className="btn btn-sm btn-danger mx-1 mt-3" type="button" aria-expanded="false">
<Link className='text-light' to="/onlinecounseling">Book Online Counselling</Link> 
</button>
<button className="btn btn-sm btn-danger mx-1 mt-3" onClick={goToTop} type="button" aria-expanded="false">
Book Offline Counselling
</button>
</div>

    </div>

    <div className="col-md-3 px-3 d-flex flex-column justify-content-center mobile-quickline">
    <h5 className="text-white text-center">Check Your IELTS Level Now ! </h5>
    <p className="text-white mb-0 text-center"><Link className="text-white" to="/level-test"> <u>Check Now</u></Link> </p>
    </div>
</div>
</div> */}
{/* quick steps ended */}

<Quicksteps/>


  {/* key features start */}
<div className="bg-light2 py-4 mb-0" id='keyfeatures'>
<div className="keyformlayout row mx-0 align-items-center">
     <div className="col-md-8">
                  <ul className="bannerPoints text-dark">
                  <h3 className='mb-4 fs-1'><span className='text-danger'>Why Attend GlobEDwise</span> Education Fair ?</h3>
						<p className='mb-3 ullist fs-5'><i className="fa fa-check-circle"></i> One to one Interaction with the University/College representatives.</p>
						<p className='my-3 ullist fs-5'><i className="fa fa-check-circle"></i> Scholarships up to 80 Lacs INR.</p>
						<p className='my-3 ullist fs-5'><i className="fa fa-check-circle"></i> Get Application Fee Waivers.</p>
						<p className='my-3 ullist fs-5'><i className="fa fa-check-circle"></i> Discover top Colleges and Universities you haven't considered yet.</p>
						<p className='my-3 ullist fs-5'><i className="fa fa-check-circle"></i> How to build a strong student profile for a successful Application.</p>
						<p className='my-3 ullist fs-5'><i className="fa fa-check-circle"></i> Know about program options available in various countries </p>
						<p className='my-3 ullist fs-5'><i className="fa fa-check-circle"></i> Explore various countries education options at one place. </p>						
					</ul>
                   
                   
     </div>
     <div className="col-md-4">
        <div className="landingform py-3">
        <img src={keyfeature} className='img-fluid' alt="key features" />
        </div>
     </div>
    </div>
</div>
  {/* key features ended */}

  <div id="whyielts">
<WhyChoose/>
</div>

{/* <YoutubeVideo/> */}

<ReasonsAbroad/>

<div className="container-xxl py-3 mt-2 mb-5 bg-light2">

        <div className="container">
            <div className="row g-5 align-items-center justify-content-center">

                <div className="col-lg-6" data-wow-delay="0.3s">
                <div className="mb-1 mt-3">
          <h2 className='display-6'><span className='text-danger'>Educational Loan for your</span><br />
          Study Abroad Dream</h2>
        </div>
        <p>Explore Loan options from top Banks & NBFCs to get the best offer</p>
        <button className="btn btn-danger shadow rounded-3 " onClick={goToTop}>Talk To an Education Loan Expert</button>
                </div>

                <div className="col-lg-4 testprepimg2" data-wow-delay="0.1s" style={{minHeight: "280px"}}>
                        <img className="img-fluid customimg" src={loan} alt="study abroad" style={{objectFit: "cover"}} />
                </div>
              
            </div>
        </div>
    </div>

{/* university */}
<div className="section-head col-sm-12" id='institutions'>
          <h4><span>GlobEDwise Students Recruited in</span> Various Institutions</h4>
          <p>Let your Dream becomes a Reality - Ease & Expertise of Overseas Education</p>
        </div>

  <div className="container text-center">
    <img src={globeduni} alt="" className="img-fluid" />
    <button className="btn btn-lg btn-danger rounded-3 shadow mt-4" onClick={goToTop}>Register For Event Now !</button>
  </div>

  {/* university */}


<NewTesti/>

  {/* faq start */}

 <Faq/>

  {/* faq ended */}

  </>
  )
}



