import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import agamdeep from '../assets/testimonials/AgamdeepSingh.png'
import testi2 from '../assets/testimonials/Akshi Ahlawat USA.png'
import testi3 from '../assets/testimonials/Aryan Mandle USA.png'
import testi4 from '../assets/testimonials/Bhumik Tandon USA.png'
import testi5 from '../assets/testimonials/HarmeharKaur.png'
import testi6 from '../assets/testimonials/Ivy Singal.png'
import testi7 from '../assets/testimonials/JeslynKataria.png'
import newstudent1 from '../assets/testimonials/newstudent1.jpeg'
import newstudent2 from '../assets/testimonials/newstudent2.jpeg'
import newstudent3 from '../assets/testimonials/newstudent3.jpeg'

const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    navText: ["<i class='fa fa-angle-left'></i> ", "<i class='fa fa-angle-right'></i>"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};

export default function NewTesti() {
  return (
    <>
     <div className="section-head col-sm-12 mt-5" id='students'>
          <h4><span>What our Students</span> Think About Us</h4>
        </div>
    <OwlCarousel className='owl-theme wow fadeInUp' {...options} loop margin={2} nav>
    <div className="item mx-2 d-flex flex-row justify-content-center">
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={agamdeep}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
              </div>
              <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              GlobEDwise thrives on excellence! An amazing event at Le Meridien, followed by regular guidance for choosing the best programs, & colleges in Canada. They have helped me immensely in my study visa approval.Cheers to their work!
              </p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Agamdeep Singh Kalra</strong></p>
              <p className="fw-bold text-muted mb-0">University of Waterloo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className='item mx-2 d-flex flex-row justify-content-center'>
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={newstudent2}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
            </div>
            <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              I'd like to begin by expressing my gratitude towards Globedwise. The team was quite helpful in locating the right university that provided the degree I wanted to pursue. They guided me through each stage of the application process, from admission to visa,</p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Simran Shah</strong></p>
              <p className="fw-bold text-muted mb-0">Deakin University, Australia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className='item mx-2 d-flex flex-row justify-content-center'>
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={newstudent3}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
            </div>
            <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              Globedwise's service has exceeded my expectations. I strongly recommend Globdwise to students who want to study abroad because it is trustworthy and provides excellent service. Their advice is essential in selecting an appropriate course that will undoubtedly be the greatest.
              </p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Geetika Saini</strong></p>
              <p className="fw-bold text-muted mb-0">Western Sydney University, Australia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className='item mx-2 d-flex flex-row justify-content-center'>
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={newstudent1}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
            </div>
            <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              The globedwise's team was with me every step of the way, from profile review to visa approval. Globedwise assisted me in putting my doubts and concerns
              My issues and worries were well discussed, and my counselor understood my needs and assisted me in achieving them.
              </p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Harmanjot Singh</strong></p>
              <p className="fw-bold text-muted mb-0">University of Canberra, Australia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className='item mx-2 d-flex flex-row justify-content-center'>
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={testi5}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
            </div>
            <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              GlobEDwise team helped me in choosing the university and I finalized the University of New South Wales, the Group 8 university. I am very happy with their services and highly recommend GlobEDwise to everyone.
              </p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Harmehar Kaur</strong></p>
              <p className="fw-bold text-muted mb-0">University of New South Wales</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className='item mx-2 d-flex flex-row justify-content-center'>
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={testi6}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
            </div>
            <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              True to its word!! Globedwise services are the best. I thank the team for helping me secure admission to the UK's most prestigious university. With your guidance & support, my journey for abroad studies became a lot easier.
              </p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Ivy Singal</strong></p>
              <p className="fw-bold text-muted mb-0">Imperial College, London</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className='item mx-2 d-flex flex-row justify-content-center'>
    <div className="col-md-6">
      <div className="testicard card">
        <div className=" card-body m-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <img src={testi7}
                className="shadow-1" alt="woman avatar" width="200" height="200" />
            </div>
            <div className="col-lg-8">
              <p className="text-muted fw-light mb-4">
              I'd like to thank Globedwise from the bottom of my heart for their unwavering support and excellent assistance throughout my application process. Globedwise was extremely helpful in making my dream a reality.
              </p>
              <div className="testicontent">
              <p className="fw-bold lead mb-2"><strong>Jeslyn Kataria</strong></p>
              <p className="fw-bold text-muted mb-0">Adelphi University, USA</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
</OwlCarousel>;
    
    
    </>
  )
}
