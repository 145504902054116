import React from 'react'
import whoweare from "../assets/img/counselingbg.jpg"

// flags import
import UK from '../assets/flags/UK.png'
import US from '../assets/flags/US.png'
import Canada from '../assets/flags/Canada.png'
import Australia from '../assets/flags/Australia.png'
import germany from '../assets/flags/Germany.jpg'

export default function Terms() {
  return (
    <>
        <div id='#customcontainer' className="customcontainer mt-5 pt-2">
<div className="blog-bg-image d-flex justify-content-center align-items-center"
  style={{backgroundImage: `url(${whoweare})`,height:'350px',backgroundSize:'cover', backgroundPosition:'top', backgroundColor:'rgba(0, 0, 0, 0.0)', backgroundBlendMode:'overlay'}}>
    
    <div className="row blogherosec w-100 mx-0 mt-5">
     <div className="col-md-8 bloglandingcontent">
     <h1 className="bannerHeading text-primary display-2">Terms & Conditions </h1>
		<h3 className='bannerHeading text-dark'>The Ease and Experience Of Overseas<span className='text-danger'> <br />
         Education </span>around the world.</h3>

                <div className="d-flex flex-row mt-3">
                    <img src={UK} className='mt-2 mx-2 img-fluid flag-img' style={{width:'80px'}}/>
                    <img src={US} className='mt-2 mx-2 img-fluid flag-img' style={{width:'80px'}}/>
                    <img src={Canada} className='mt-2 mx-2 img-fluid flag-img' style={{width:'80px'}}/>
                    <img src={Australia} className='mt-2 mx-2 img-fluid flag-img' style={{width:'80px'}}/>
                    <img src={germany} className='mt-2 mx-2 img-fluid flag-img rounded-3' style={{width:'80px'}}/>
                </div>
     </div> 

     
    </div>
 
</div>

 </div>
    
    <div className="termssec container mt-5">

        <div className="termspara">
            <h3>I.GENERAL</h3>
            <p>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Globedwise Private Limited’s relationship with you in relation to this website/mobile and the product which you purchase through this website/mobile . The term ‘Globedwise Private Limited’ or ‘us’ or ‘we’ refers to the owner of the website/mobile . The term ‘you’ refers to the user/viewer of our website.</p>

            <p>This Agreement is an electronic record in terms of Information Technology Act, 2000, and generated by a computer system and does not require any physical or digital signatures.</p>
        </div>
        
        <div className="termspara">
            <h3>II.Globedwise FEE</h3>
            <p>Globedwise has an arrangement (directly/indirectly) with the universities. Globedwise earns after successful enrolment of a student in a University, as per their norms.</p>
        </div>

        <div className="termspara">
            <h3>III.ABOUT US</h3>
            <h5>The use of this platform and the products purchased on this platform is subject to the following terms of use:</h5>
            <p>The content of the pages of this platform is for your general information and use only. It is subject to change without notice.</p>

            <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this platform for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>

            <p>Your use of any information or materials on this platform is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products or information available through this platform meet your specific requirements.</p>

            <p>This platform contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions</p>

            <p>All trademarks reproduced in this platform which are not the property of, or licensed to, the operator are acknowledged on the website.No use of the intellectual property owned or licensed to Globedwise Private Limited may be used without our prior written authorization. Unauthorised use of this platfrom may give rise to a claim for damages and/or be a criminal offence.</p>

            <p>From time to time this platfrom may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s). You may not create a link to  this website from another platfrom any other lead source, or document without Globedwise Private Limited’s prior written consent.</p>

            <p>Your use of this platfrom and any dispute arising out of such use of the platfrom is subject to the laws of India or other regulatory authority.Your use of our products and services provided for herein is solely for your personal and non-commercial use. Any use other than for personal purposes is prohibited. Your personal and non-commercial use of our services shall be subjected to the following restrictions: <br />
            i:- You may not decompile, reverse engineer, or disassemble the contents of our products, or (c) remove any copyright, trademark registration, or other proprietary notices from the contents of our products. <br />
            ii:- You will not (a) use our product or service for commercial purposes of any kind, or (b) advertise or sell any products, services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or (c) use our products and services in any way that is unlawful, or harms Globedwise Private Limited or any other person or entity as determined by Globedwise Private Limited.</p>

            <p>Globedwise Private Limited products and / or services, including the Globedwise Private Limited application and content, may not be compatible to all devices. Globedwise Private Limited shall not be obligated to provide workable products and / or services for any phones/tablets unless stated otherwise</p>

            <p>Globedwise Private Limited or any of its subsidiaries or content partners shall have no responsibility for any loss or damage caused to tablet or phone or any other hardware and / or software, including loss of data or effect on the processing speed of the tablet, those results from the use of our products and services.</p>

            <p>In no event will Globedwise Private Limited. be liable for damages of any kind, including without limitation, (i) indirect, punitive, special, exemplary, incidental, or consequential damage (including loss of business, revenue, profits, use, data or other economic advantage, loss of programs or information), or (ii) direct damages in excess of the amount paid to Globedwise Private Limited for the product and / or service, arising out of the use of, misuse of or inability to use, or errors, omissions or other inaccuracies in the Site or the products and / or services, or any information provided on the Site. Some jurisdictions do not allow the limitation or exclusion of liability. Accordingly, in such instances, some of the above limitations may not apply to the User.</p>

            <p>Globedwise Private Limited may also contact the user through sms, email and call to give notifications on various important updates. Therefore, User holds Globedwise Private Limited non liable to any liabilities including financial penalties, damages, expenses in case the users mobile number is registered with Do not Call (DNC) database.</p>

            <p>You hereby indemnify, defend, and hold Globedwise Private Limited’s distributors, agents, representatives and other authorised users, and each of the foregoing entities” respective resellers, distributors, service providers and suppliers, and all of the foregoing entities’ respective officers, directors, owners, employees, agents, representatives, harmless from and against any and all losses, damages, liabilities and costs arising from your use of our product.</p>

            <p>In the event of breach of the terms and conditions of this Terms of Use by the User, the User shall be promptly liable to indemnify Globedwiseh Private Limited for all the costs, losses and damages caused to Globedwise Private Limited as a result of such a breach. In the event of your breach of this Terms of Use, you agree that Globedwiseh Private Limited will be irreparably harmed and will not have an adequate remedy in money or damages. Globedwise Private Limited therefore, shall be entitled in such event to obtain an injunction against such a breach from any court of competent jurisdiction immediately upon request. Globedwise Private Limited’s right to obtain such relief shall not limit its right to obtain other remedies.</p>

            <p>Persons who are “competent/capable” of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to use our products or services. Persons who are minors, un-discharged insolvents etc. are not eligible to use our products or services. As a minor if you wish to use our products or services, such use shall be made available to you by your legal guardian or parents. Globedwise Private Limited will not be responsible for any consequence that arises as a result of misuse of any kind of our products or services that may occur by virtue of any person including a minor using the services provided. By using the products or services you warrant that all the data provided by you is accurate and complete and that you have obtained the consent of parent/legal guardian(in case of minors). Globedwise Private Limited reserves the right to terminate your subscription and / or refuse to provide you with access to the products or services if it is discovered that you are under the age of 18 years and the consent to use the products or services is not made by your parent/legal guardian or any information provided by you is inaccurate.</p>

            <h4>The users of this platform shall not host, display, upload, modify, publish, transmit, update or share any information that:</h4>

            <ul>
                <li> to another person and to which the user does not have any right to;</li>
                <li>is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever</li>
                <li>harm minors in any way;</li>
                <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                <li>violates any law for the time being in force;</li>
                <li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                <li>impersonate another person;</li>
                <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource</li>
                <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation</li>
            </ul>
        </div>

        <div className="termspara">
            <h3>IV.TERM & TERMINATION</h3>
            <p>This Agreement will remain in full force and effect until terminated, for using the platform or purchasing the products in any form or capacity. Provided, we have the right to immediately terminate your access or usage and remove non-compliant information or material, in case of non-compliance with the terms and conditions or our privacy policy</p>

            <p>The User shall be obligated to clear any dues with us for any of its Services which you have procured. Globedwise Private Limited shall not be liable to you or any third party for any termination of your access to the Services.</p>
        </div>

        <div className="termspara">
            <h3>V.GOVERNING LAW</h3>
            <p>The jurisdictional court of New Delhi, India shall have sole jurisdiction over any claim arising from, or related to, a visit to / use of the platform or purchase of the products. In case you are from a different country, we retain the right to bring proceedings against you for breach of any of the terms and conditions in your country of residence, country of use or other relevant country. The laws of New Delhi, India govern this Agreement and these terms and conditions of use of the platform or purchase of the products.</p>

            <p>Globedwise Private Limited accepts no liability whatsoever, direct or indirect, for non-compliance with the laws of any country other than that of India, and the mere fact that the platform can be accessed or used in a country other than India will not imply that we accede to the laws of such country.</p>
        </div>

        <div className="termspara">
            <h3>VI.CONTACT US</h3>
            <p>If you have any questions or complaints about the online training package, the platform and their contents please contact us at info@globedwise.com </p>
        </div>

        <div className="termspara">
            <h3>VII.UniConnect's Terms & Conditions</h3>
            <p>The student agrees to authorise Globedwise as his/her agent and help with his/her applications & other admission related services. The student agrees to change his/her agent to Globedwise in case there is another agent already authorised prior to this.</p>
        </div>

        <div className="termspara">
            <h3>VIII.Globedwise Scholarship Terms & Conditions</h3>
            <ul>
            <li>The reward will be processed once you pay the deposit at the university and receive your Visa</li>
            <li>The scholarship amount is to be determined on the basis of your application and overall profile</li>
            <li>Selection for the scholarship to be solely at the discretion of the Globedwise jury and corresponding guidelines</li>
            </ul>
        </div>
        
        <div className="termspara">
            <h3>IX.Handling of Student Financial Awards</h3>
            <p>Globedwise does not collect any remuneration from students that is conditional upon the receipt of scholarships and financial aid monies awarded by partner institutions, or other scholarship awarding organizations.</p>
        </div>
        
        <div className="termspara">
            <h3>X.Payments</h3>
            <p>Globedwise does not require you to make payments for any purposes in any individual’s bank accounts. Any payments for any purpose including purposes of applications, deposit, tuition fee payments or to purchase any service packages offered by Globedwise must be made through the official Globedwise payment channels or specific university payment accounts, as the case maybe</p>
        </div>
        
        <div className="termspara">
            <h3>XI.Financial Services</h3>
            <p>The sanction of the loan amount, interest rate, and the processing time depends upon the applicant’s eligibility and financial institutions criteria, discretion, and applicable rules and regulations.</p>
        </div>

    </div>
    
    </>
  )
}
